import { t } from "i18next";
import React from "react";

const ModalAgendedLiveKit = ({ setMessage, message, show, getFeedBack }) => {
  
  return (
    <div className="absolute h-full w-full flex justify-center items-center bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 z-[61] top-0">
      <div className="relative rounded-lg bg-pr-900 flex flex-col justify-between items-center w-[90%] md:w-[30%] p-10 gap-10">
        <div className="w-full flex flex-col justify-center items-center gap-10">
          <h2 className="text-base md:text-xl font-bold text-bunker-600 text-center">
            Tu sesión ha finalizado
          </h2>
          <p className="text-sm md:text-md font-bold w-[90%] text-center">
            {message === "Daily session limit reached" 
              ? t("has-alcanzado-el-limite-de-sesiones-diarias")
              : message === "Weekly session limit reached"
              ? t("has-alcanzado-el-limite-de-sesiones-semanales")
              : message === "Session timeout reached" || message === "Final message completed (fallback)" ?
                t(
                  "se-ha-acabado-el-tiempo-de-tu-sesion-gracias-por-participar"
                ): null}
          </p>
        </div>
        <div className="w-full flex flex-col justify-center gap-8 items-center">
          <div className="w-full justify-center gap-4 items-center flex">
            {/* <button className="btn px-10 hover:bg-pr-800 bg-bunker-900 border hover:border-bunker-800 border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition">
              Agendar
            </button> */}
            <button
              onClick={() => {
                setMessage(false);
                if(message === "Session timeout reached" || message === "Final message completed (fallback)"){
                  getFeedBack()
                }
              }}
              className="btn px-10 bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700   hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition"
            >
              {message === "Session timeout reached" || message === "Final message completed (fallback)" ? "Obtener feedback" : "Nos vemos en la proxima!"}
            </button>
            {/* <button className="btn px-10 bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition"
            onClick={()=>setShowAgended(true)}>
              {t('schedule-a-session')}
            </button> */}
            {message === "Weekly session limit reached" && (
              <a
                href="https://calendar.app.google/fe9eXxDxFDNSSC7i6"
                target="_blank"
                rel="noreferrer"
                className="btn px-10 bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700   hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition"
              >
                {t("schedule-a-session")}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalAgendedLiveKit;
