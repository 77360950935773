import { useMaybeRoomContext } from "@livekit/components-react";
import { RoomEvent } from "livekit-client";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { IoClose } from "react-icons/io5";
import { UserContext } from "../utils/userContext";
import { MdOutlineTranslate } from "react-icons/md";
import axios from "axios";

const TranscriptionComponent = forwardRef((props, ref) => {
  const room = useMaybeRoomContext();
  const [transcriptions, setTranscriptions] = useState({});
  const containerRef = useRef(null);
  const { user } = useContext(UserContext);
  const [translatedTexts, setTranslatedTexts] = useState({});
  const [loading, setLoading] = useState(false);
  const lastTextRef = useRef({});


  const hideSide = () => {
    
    props.setIsSideOpen(false);
    if(props.state === "hide"){      
      props.sideState("show");
    }
  };

  const clearTranscriptions = () => {
    setTranscriptions({});
  };

  useImperativeHandle(ref, () => ({
    clearTranscriptions,
  }));

  const translateText = async (id, text) => {
    if (translatedTexts[id]) {
      setTranslatedTexts((prevState) => {
        const newState = { ...prevState };
        delete newState[id]; // Elimina la traducción para ese id
        return newState;
      });
      return; // Salir de la función si se está eliminando la traducción
    }
    
    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_URL_TRANSCRIPTION,{
          text: text
        },{
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
          },
        }
      );
      setLoading(false);
      
      setTranslatedTexts((prevState) => ({
        ...prevState,
        [id]: response.data.translated_text,
      }));
    } catch (error) {
    }
  };

  const formatTranscript = (text) => {
    return text && text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>"); // Negrita
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [transcriptions]);

  useEffect(() => {
    if (!room || !user) {
      return;
    }

    const updateTranscriptions = (segments, participant, publication) => {
      setTranscriptions((prev) => {
        const newTranscriptions = { ...prev };
        for (const segment of segments) {
          newTranscriptions[segment.id] = {
            ...segment,
            origin: participant.isLocal ? user?.full_name : "Nina", // Diferenciar origen
          };
        }
        return newTranscriptions;
      });

      props.setTranscriptionCompleted((prev) => {
        const newTranscriptions = [...prev];

        for (const segment of segments) {
          const newText = segment.text.trim();
          const speaker = participant.isLocal ? user?.full_name : "Nina";

          // Si el speaker tiene un texto previo, extraer solo la parte nueva
          if (lastTextRef.current[speaker]) {
            const prevText = lastTextRef.current[speaker];

            // Remover la parte repetida al inicio
            const cleanText = newText.replace(prevText, "").trim();

            if (cleanText) {
              newTranscriptions.push({
                id: segment.id,
                text: cleanText,
                origin: speaker,
              });

              // Guardar este texto como el nuevo último texto recibido
              lastTextRef.current[speaker] = newText;
            }
          } else {
            // Primer fragmento de texto del speaker, se almacena completo
            newTranscriptions.push({
              id: segment.id,
              text: newText,
              origin: speaker,
            });

            lastTextRef.current[speaker] = newText;
          }
        }

        return newTranscriptions;
      });
    };

    room.on(RoomEvent.TranscriptionReceived, updateTranscriptions);
    // room.on(RoomEvent.LocalTrackPublished, (track) => {
    //   console.log(track);
    // });
    // room.on(RoomEvent.LocalTrackPublished, (track) => {
    //   console.log(track);
    // });
    return () => {
      room.off(RoomEvent.TranscriptionReceived, updateTranscriptions);
    };
  }, [room, user]);

  useEffect(() => {
    if (transcriptions) {
      Object.values(transcriptions)
        .sort((a, b) => a.firstReceivedTime - b.firstReceivedTime)
        .map((segment) => {
          return props.setTranscriptionAssistant(segment.text);
        });
    }

    // (
    //   <div className={`flex flex-col w-full gap-1`}>
    //     <span className={`${
    //     segment.origin !== 'Nina' ? 'text-end' : 'text-start'
    //   } text-xs w-auto`}>{segment.origin}</span>
    //   <div
    //     key={segment.id}
    //     className={` relative p-2 rounded-md flex w-auto max-w-[80%] ${
    //       segment.origin !== 'Nina' ? 'bg-pr-200 justify-end ml-auto ' : 'bg-blue-700 justify-start mr-auto'
    //     }`}
    //   >
    //     <p className="text-white">{segment.text}</p>
    //   </div>
    //   </div>
    // ))
  }, [transcriptions]);

  useEffect(() => {
    const receiveMetadata = (metadata) => {
      let metadataParse = JSON.parse(metadata);
      props.messageAggended(metadataParse);
      if (metadataParse.session_closed) {
        clearTranscriptions();
      }
    };
    room.on(RoomEvent.RoomMetadataChanged, receiveMetadata);
    return () => {
      room.off(RoomEvent.RoomMetadataChanged, receiveMetadata);
    };
  }, [room]);

  return (
    <div
      ref={containerRef}
      className={`${
        props.isSideOpen
          ? "translate-x-0 max-w-full md:max-w-80 p-2 py-3 border-l"
          : "-translate-x-full max-w-0 p-0"
      } bg-pr-800 h-[91vh] w-full pb-20 md:pb-2  border-pr-400  custom-transition z-[51] md:z-[30] absolute md:relative overflow-hidden overflow-y-scroll flex flex-col gap-10`}
    >
      {props.isSideOpen && (
        <IoClose
          onClick={hideSide}
          className="text-white text-xl fixed right-2 top-2 cursor-pointer"
        />
      )}
      <h1 className="text-center font-semibold text-white uppercase mt-5">
        Transcriptions
      </h1>
      {/* <pre>{JSON.stringify(transcriptions, null, 2)}</pre> */}
      <div className="flex flex-col gap-2">
        {/* {Object.values(transcriptions).map((transcription) => (
            <div key={transcription.id} className='bg-bunker-700 p-2 rounded-md'>
              <p className='text-white'>{transcription.text}</p>
            </div>
          ))} */}
        {Object.values(transcriptions)
          .sort((a, b) => a.firstReceivedTime - b.firstReceivedTime)
          .map((segment) => (
            <div className={`flex flex-col w-full gap-1`}>
              <span
                className={`${
                  segment.origin !== "Nina" ? "text-end" : "text-start"
                } text-xs w-auto `}
              >
                {segment.origin}
              </span>
              <div
                key={segment.id}
                className={` relative p-2 rounded-md flex w-auto max-w-[90%] gap-2 ${
                  segment.origin !== "Nina"
                    ? "bg-pr-200 justify-end ml-auto "
                    : "bg-[#1B2A4E] justify-start mr-auto"
                }`}
              >
                <p
                  className={`text-white ${
                    translatedTexts[segment.id] && "font-bold"
                  }`}
                  dangerouslySetInnerHTML={{
                    __html:
                      formatTranscript(translatedTexts[segment.id]) ||
                      formatTranscript(segment.text),
                  }}
                ></p>

                {segment.origin === "Nina" && (
                  <button
                    onClick={() => translateText(segment.id, segment.text)}
                    className="rounded-full p-2 text-black bg-white hover:bg-transparent hover:text-white h-[30px] w-[40px] transition-all duration-200 ease-in-out"
                  >
                    {loading ? (
                      <span className="loading loading-spinner loading-xs text-black"></span>
                    ) : (
                      <MdOutlineTranslate />
                    )}
                  </button>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
});

export default TranscriptionComponent;
