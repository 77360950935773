import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

const Timer = (props) => {
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    useEffect(() => {
      const timer = setInterval(() => {
        if (seconds === 59) {
          setMinutes(minutes + 1);
          props.setMinutes(minutes + 1)
          setSeconds(0);
        } else {
          setSeconds(seconds + 1);
        }
      }, 1000);
  
      return () => clearInterval(timer);
    }, [minutes, seconds]);
    
  
    return (
        <p className='font-semibold text-white text-sm md:text-base'>
          {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
        </p>
    );
  };
export default Timer