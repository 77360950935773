import React, { useState, useRef, useEffect } from "react";

const VideoBoxLivekit = ({ showSide, assistant, state }) => {
  const [position, setPosition] = useState({ top: "auto", left: "auto" });
  const [isMobile, setIsMobile] = useState(false);
  const dragRef = useRef(null);

  // Detecta el cambio de tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Se ajusta al breakpoint de dispositivos móviles
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseDown = (e) => {
    if (!isMobile || showSide !== "hide") return;

    const offsetX = e.clientX - dragRef.current.getBoundingClientRect().left;
    const offsetY = e.clientY - dragRef.current.getBoundingClientRect().top;

    const handleMouseMove = (moveEvent) => {
      // Calcula la nueva posición
      let newLeft = moveEvent.clientX - offsetX;
      let newTop = moveEvent.clientY - offsetY;

      // Limita la posición para que no se salga de la pantalla
      const minLeft = 0; // No puede ir más a la izquierda
      const minTop = 0; // No puede ir más arriba
      const maxLeft = window.innerWidth - dragRef.current.offsetWidth; // No puede ir más a la derecha
      const maxTop = window.innerHeight - dragRef.current.offsetHeight; // No puede ir más abajo

      newLeft = Math.max(minLeft, Math.min(newLeft, maxLeft)); // Limita la posición horizontal
      newTop = Math.max(minTop, Math.min(newTop, maxTop)); // Limita la posición vertical

      // Actualiza la posición
      setPosition({
        left: newLeft,
        top: newTop,
      });
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e) => {
    if (!isMobile || showSide !== "hide") return;

    const touch = e.touches[0];
    const offsetX =
      touch.clientX - dragRef.current.getBoundingClientRect().left;
    const offsetY = touch.clientY - dragRef.current.getBoundingClientRect().top;

    const handleTouchMove = (moveEvent) => {
      const touchMove = moveEvent.touches[0];

      // Calcula la nueva posición
      let newLeft = touchMove.clientX - offsetX;
      let newTop = touchMove.clientY - offsetY;

      // Limita la posición para que no se salga de la pantalla
      const minLeft = 0; // No puede ir más a la izquierda
      const minTop = 0; // No puede ir más arriba
      const maxLeft = window.innerWidth - dragRef.current.offsetWidth; // No puede ir más a la derecha
      const maxTop = window.innerHeight - dragRef.current.offsetHeight; // No puede ir más abajo

      newLeft = Math.max(minLeft, Math.min(newLeft, maxLeft)); // Limita la posición horizontal
      newTop = Math.max(minTop, Math.min(newTop, maxTop)); // Limita la posición vertical

      // Actualiza la posición
      setPosition({
        left: newLeft,
        top: newTop,
      });
    };

    const handleTouchEnd = () => {
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };

    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
  };

  return (
    <div
      ref={dragRef}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      className={`${
        showSide === "hide"
          ? "top-2 left-2 w-[200px] h-[140px] rounded-full md:top-0 md:left-auto md:w-full md:h-[89vh] z-[52] md:z-auto"
          : "w-full h-[89vh] top-0"
      } absolute md:rounded-t-lg transition-all duration-200 ease-in-out`}
      style={
        isMobile && showSide === "hide"
          ? { top: `${position.top}px`, left: `${position.left}px` }
          : {}
      }
    >
      <video
        src={assistant?.idle_video}
        autoPlay={true}
        loop={true}
        playsInline
        muted
        className={`${
          showSide === "hide" && "rounded-lg md:rounded-none"
        } absolute w-full h-full object-cover md:object-contain md:rounded-t-lg z-[11] ${
          state !== "speaking" || state === "disconnected"
            ? "!z-20"
            : state === "speaking" && "!-z-10"
        }`}
      />
      <video
        src={assistant?.speaking_video}
        autoPlay={true}
        loop={true}
        playsInline
        muted
        className={`${
          showSide === "hide" && "rounded-lg md:rounded-none"
        } relative w-full h-full object-cover md:object-contain md:rounded-t-lg -z-10 ${
          state === "speaking" && "z-10"
        }`}
      />
    </div>
  );
};

export default VideoBoxLivekit;
