import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaMicrophone, FaStop } from "react-icons/fa";
import "../../assets/css/effectCall.css";
import { MdCallEnd } from "react-icons/md";

const CallPage = (props) => {
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth0();
  const token = window.localStorage.getItem("token_user");

  const showModalInactive = () => {
    const modalI = document.querySelector(".modalI");

    modalI.classList.add("flex");
    modalI.classList.remove("hidden");
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 128; // Ajuste del tamaño de la FFT
        const bufferLength = analyserRef.current.frequencyBinCount;
        dataArrayRef.current = new Uint8Array(bufferLength);

        source.connect(analyserRef.current);

        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();
        setRecording(true);

        mediaRecorder.ondataavailable = (event) => {
          audioChunks.current.push(event.data);
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          // const audioUrl = URL.createObjectURL(audioBlob);
          // setAudioURL(audioUrl);
          audioChunks.current = [];
          uploadAudio(audioBlob);
        };
      })
      .catch((error) => console.error("Error accessing audio devices.", error));
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      props.setLoading(true);
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    }
  };

  const uploadAudio = (blob) => {
    const formData = new FormData();
    formData.append("audio", blob, "audio.wav");

    try {
      axios
        .post(process.env.REACT_APP_URL_AUDIO, formData, {
          headers: {
            "X-API-KEY": process.env.REACT_APP_X_APIKEY,
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {

          props.sendMessage(res.data.transcription);
        })
        .catch((err) => {
          if (err.response?.data.detail === "User is inactive") {
            showModalInactive();
          }
        });
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  useEffect(() => {
    if (props.status === "connected") {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } else if (props.status === "connecting") {
      setLoading(true);
    }
  }, [props.status]);

  useEffect(() => {
    if (props.isPlaying) {
      videoRef.current.play();
      videoRef.current.muted = true
      videoRef.current.loop = true
    } else {
      videoRef.current.pause();
    }
    
  }, [props.isPlaying])
  

  return (
    <div className="absolute h-full min-h-screen w-full flex flex-col justify-between items-center">
      {/* <audio
        ref={audioRef}
        src={props.audio}
        onEnded={handleAudioEnded}
      ></audio> */}
      {/* <audio ref={audioElementRef} /> */}
      <div className="h-[90%] w-full flex  justify-center items-center bg-main2 dark:bg-white">
        <div className="w-[70%] flex flex-col gap-5 md:gap-0 md:flex-row justify-evenly items-center ">
          <div className="w-[250px]  h-[250px] md:w-[300px] md:h-[300px] relative">
            <div
              className={`rounded-full w-full h-full ${
                recording ? "animate-pulse playing " : ""
              }`}
            />
            <video
              autoPlay={true}
              className="video rounded-lg w-full absolute top-0 left-0 h-full object-cover z-[11]"
            />
            <img
              src={user.picture}
              alt="user"
              className="w-full h-full object-cover rounded-lg absolute top-0 left-0"
            />
          </div>
          <div className="w-[250px]  h-[250px] md:w-[300px] md:h-[300px] relative">
            {loading && (
              <div className="absolute w-full h-full rounded-lg bg-[#00000081] flex justify-center items-center z-50">
                <span className="loading loading-dots loading-md"></span>
              </div>
            )}
            {props.loading && (
              <div className="absolute w-full h-full rounded-lg bg-[#00000081] flex justify-center items-center z-50">
                <span className="loading loading-spinner loading-sm"></span>
              </div>
            )}
            <div
              className={` rounded-full w-full h-full ${
                props.isPlaying ? "animate-pulse playing " : ""
              }`}
            />
            {/* <video ref={videoRef} src={Video} className="w-full h-full object-cover rounded-lg absolute top-0 left-0 "/> */}
            <img
            src={props.assistant.image}
            alt="user"
            className={`w-full h-full object-cover rounded-lg absolute top-0 left-0 ${props.isPlaying ? "hidden" : "block"}`}
          />
            
          </div>
        </div>
      </div>
      <div className="h-[10%] w-full flex justify-center items-center bg-pr-600 dark:bg-[#f9f9f9] px-10">
        <div className="w-1/2 h-full flex justify-center items-center gap-2">
          {recording ? (
            <button
              onClick={stopRecording}
              className="p-2 rounded-full border-[1px] border-[#29292E] dark:border-none hover:bg-[#1F1F23] dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer"
            >
              <FaStop className="text-[#515153] dark:text-[#7d7d7d] text-xl" />
            </button>
          ) : (
            !loading && (
              <button
                disabled={props.loading ? true : props.isPlaying && true}
                id="mic"
                onClick={startRecording}
                className="p-2 rounded-full border-[1px] border-green-400 dark:border-none hover:bg-green-600 dark:hover:bg-[#f9f9f9] custom-transition flex justify-center items-center  cursor-pointer"
              >
                <FaMicrophone className="text-green-400 dark:text-[#7d7d7d] text-xl" />
              </button>
            )
          )}
          <button
            onClick={() => {
              props.setCallActive(false);
              props.setMode("text");
              // handleSpeak("Hello, how can I assist you today?");
              props.amplitude.track("Call end (Modo llamada)", {
                userId: props.user.id,
                userEmail: props.user.email,
                userName: props.user.full_name,
                buttonName: "Call",
                page: "Chat",
                assistant: props.assistant.name,
                assistantId: props.assistant.assistant_id,
                assistantRole: props.assistant.role,
              });
            }}
            className="p-2 rounded-full border-[1px] border-red-600 dark:border-none bg-red-500 hover:bg-red-600  custom-transition flex justify-center items-center  cursor-pointer"
          >
            <MdCallEnd className="text-xl text-bunker-50" />
          </button>
          {/* <button onClick={startVideo}>Start</button> */}
        </div>
      </div>
    </div>
  );
};

export default CallPage;
