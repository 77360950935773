import React from "react";
import { IoClose } from "react-icons/io5";

const ModalFeedbackSession = (props) => {
  return (
    <div className="absolute h-full w-full flex justify-center items-center bg-[#0000003c] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 z-[60] top-0">
      <div className="relative rounded-lg bg-pr-900 flex flex-col justify-between items-center w-[90%] md:w-[60%] overflow-y-scroll max-h-[80%] lg:max-h-[70%] p-4 lg:p-10 gap-10">
        {/* <IoClose
          className="absolute top-2 right-2 text-white cursor-pointer text-2xl"
          onClick={() => {
            props.show(false);
          }}
        /> */}
        <div className="w-full flex flex-col justify-center items-center gap-2 lg:gap-5">
          <div className="flex flex-col justify-center items-center gap-3">
            <h2 className="text-base md:text-2xl font-bold text-bunker-600 text-center uppercase">
              Resumen
            </h2>
          </div>
          <div className="flex justify-center items-center w-full gap-4  p-4">
            <p
              className="text-white text-sm lg:text-lg"
              dangerouslySetInnerHTML={{ __html: props.data }}
            ></p>
          </div>
        </div>
        <div className="w-full flex flex-col justify-center gap-8 items-center">
          <div className="w-full justify-center gap-4 items-center flex">
            {/* <button className="btn px-10 hover:bg-pr-800 bg-bunker-900 border hover:border-bunker-800 border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition">
                  Agendar
                </button> */}
            <button
              onClick={() => {
                props.getSessions()
                props.show(false)
              }}
              className="lg:btn-lg btn-sm px-10 bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700   hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition"
            >
              Conocer conteo de sesiones
            </button>
            {/* <button className="btn px-10 bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition"
                onClick={()=>setShowAgended(true)}>
                  {t('schedule-a-session')}
                </button> */}
            {/* <a href="https://calendar.app.google/fe9eXxDxFDNSSC7i6" target="_blank" rel='noreferrer' className="btn px-10 bg-pr-800 hover:bg-bunker-900 border border-bunker-800 hover:border-bunker-700 dark:bg-transparent dark:border-none dark:hover:bg-[#7d7d7d] hover:text-bunker-50 text-xs font-semibold rounded-xl custom-transition">
                  {t('schedule-a-session')}
                </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFeedbackSession;
