import React from 'react'
import '../../assets/css/loader.css'

const FeedbackAnimation = () => {
  return (
    <div className="w-full min-h-screen flex flex-col justify-center gap-20 items-center py-[30px] absolute top-0 bottom-0 left-0 right-0 bg-black z-[70]">
            <h1 className="text-white text-xl lg:text-4xl font-bold text-center uppercase">
            Lo hiciste excelente!, estamos generando tu retroalimentación.
            </h1>
            <span className="loader"></span>
      </div>
  )
}

export default FeedbackAnimation